import BNAdElement from "./elements/b-a-d-no-consent-element";
import { getCookie } from "./helpers/cookie-helper";
import { activateDeveloperConsole } from "./helpers/developer-console";
import badEvents from "./static/bad-events";

async function setupNoConsentAds() {
  const urlParams = new URLSearchParams(window.location.search);

  if (urlParams.has("bauConsole") || getCookie("bauConsole") !== null){
    activateDeveloperConsole();
  }

  let deprecationWarningUsed = false;
  // We add an interceptor that catches the event ´bad:clas:slotRenderEnded´ and throws it.
  window.addEventListener(badEvents.CLAS_SLOT_RENDER_ENDED, (event) => {

    if (!deprecationWarningUsed) {
      console.warn("The event 'bad:clas:slotRenderEnded' is deprecated and will be removed in a future release. Please use the event 'bad:slotRenderEnded' instead.");
      deprecationWarningUsed = true;
    }

    event.stopImmediatePropagation();
  }, true)

  customElements.define("b-a-d", BNAdElement);
}

export { setupNoConsentAds };
