import { setupAds } from "./b-a-d.js";
import { setupNoConsentAds } from "./b-a-d-no-consent.js";
import { hasValidConsent } from "./helpers/consent.js";
import setupBam from "./helpers/setup-bam.js";

// We respect the TCF consent, so we need to wait for it to be loaded, then we can proceed with loading all our ads.
// If we don't have valid consent from the cmp, we reject the promise and don't load any ads

new Promise(async (resolve, reject) => {
  await setupBam().catch((e) => {
    console.error("Failed to setup BAM, no ads will load. Contact @au on slack");
    reject(e);
  });

  // no Didomi is available so we can't check for consent, if it does not exist we load in ads through CLAS.
  if (typeof window.__tcfapi !== "function") return resolve(false);

  window.__tcfapi("addEventListener", 2, (tcData, success) => {
    if ((success && tcData.eventStatus === "tcloaded") || tcData.eventStatus === "useractioncomplete") {
      window.didomiOnReady = window.didomiOnReady || []
      window.didomiOnReady.push(() => {
        return resolve(hasValidConsent());
      });
    }
  });
})
  .then(async (validConsent) => {
    if (validConsent) {
      try {
        await setupAds();
      } catch (err) {
        console.log("BAD setup error: ", err);
      }
    } else {
      try {
        await setupNoConsentAds();
      } catch (err) {
        console.log("BAD setup for no consent ads error: ", err);
      }
    }
  })
  .catch((err) => {
    console.log("Something went wrong with BAD setup: ", err);
  });

// Eventually if we need to get the site headers to check for x-channel header.
async function getSiteHeaders() {
  var req = new XMLHttpRequest();
  req.open("GET", document.location, true);
  return new Promise((res) => {
    req.send(null);
    req.onload = () => {
      res(req.getAllResponseHeaders().toLowerCase());
    };
  });
}
