function getConsentStatus() {
  return window.Didomi?.getCurrentUserStatus // new function to get consent status
  ? window.Didomi.getCurrentUserStatus()
  : window.Didomi?.getUserStatus // deprecated function to get consent status
  ? window.Didomi.getUserStatus()
  : undefined;
}

function getLegitimateInterestConsent() {
  return window.Didomi?.getUserStatus
    ? window.Didomi.getUserStatus()
    : undefined;
}

function hasVendorConsent(consentStatus, vendorId, altVendorId) {
  return consentStatus.vendors[vendorId] !== undefined
    ? consentStatus.vendors[vendorId].enabled
    : consentStatus.vendors.global?.enabled.includes(altVendorId || vendorId);
}

function hasVendorLegitimateInterest(legitimateInterestConsent, vendorId) {
  return legitimateInterestConsent !== undefined
    ? legitimateInterestConsent.vendors.legitimate_interest.enabled.includes(vendorId)
    : false;
}

function hasPurposeConsent(consentStatus, legitimateInterestConsent, purposes) {
  return purposes.every((purpose) => {
    const purposeConsent = consentStatus.purposes[purpose] !== undefined
      ? consentStatus.purposes[purpose].enabled
      : consentStatus.purposes.global?.enabled.includes(purpose);

    const purposeLegitimateInterest = legitimateInterestConsent !== undefined
      ? legitimateInterestConsent.purposes.legitimate_interest.enabled.includes(purpose)
      : false;

    return purposeConsent || purposeLegitimateInterest;
  });
}

export function hasValidConsent() {
  let consentStatus = getConsentStatus();

  // no consent status, return false for CLAS
  if (!consentStatus) return false;

  const legitimateInterestConsent = getLegitimateInterestConsent();

  const googleConsent = hasVendorConsent(consentStatus, "google");
  const googleLegitimateInterest = hasVendorLegitimateInterest(legitimateInterestConsent, "google");

  const bonnierNewsConsent = hasVendorConsent(consentStatus, "bonniernews", "c:bonniernews");
  const bonnierNewsLegitimateInterest = hasVendorLegitimateInterest(legitimateInterestConsent, "c:bonniernews");

  const bonnierNewsTailsweepConsent = hasVendorConsent(consentStatus, "bn-tailsweep-bonniernews", "c:bn-tailsweep-bonniernews");
  const bonnierNewsTailsweepLegitimateInterest = hasVendorLegitimateInterest(legitimateInterestConsent, "c:bn-tailsweep-bonniernews");

  const bonnierNewsNetdoktorConsent = hasVendorConsent(consentStatus, "bn-netdoktor-bonniernews", "c:bn-netdoktor-bonniernews");
  const bonnierNewsNetdoktorLegitimateInterest = hasVendorLegitimateInterest(legitimateInterestConsent, "c:bn-netdoktor-bonniernews");

  const bonnierNewsNetdoktorproConsent = hasVendorConsent(consentStatus, "bn-netdoktorpro-bonniernews", "c:bn-netdoktorpro-bonniernews");
  const bonnierNewsNetdoktorproLegitimateInterest = hasVendorLegitimateInterest(legitimateInterestConsent, "c:bn-netdoktorpro-bonniernews");

  const purposesConsent = hasPurposeConsent(consentStatus, legitimateInterestConsent, ["select_basic_ads", "measure_ad_performance", "market_research", "improve_products"]);

  return (
    (googleConsent || googleLegitimateInterest) &&
    (
      bonnierNewsConsent || bonnierNewsLegitimateInterest || // general BonnierNews consent
      bonnierNewsTailsweepConsent || bonnierNewsTailsweepLegitimateInterest || // tailsweep consent
      bonnierNewsNetdoktorConsent || bonnierNewsNetdoktorLegitimateInterest || // netdoktor consent
      bonnierNewsNetdoktorproConsent || bonnierNewsNetdoktorproLegitimateInterest // netdoktorpro consent
    ) &&
    purposesConsent
  )
}

export function hasAudienceConsent() {
  const consentStatus = getConsentStatus();

  if (!consentStatus) return false;

  const googleAnalyticConsent = hasVendorConsent(consentStatus, "googleanalytics", "c:googleanalytics");
  const bonnierNewsReynoldsConsent = hasVendorConsent(consentStatus, "bonniernews-reynolds", "c:bonniernews-reynolds");

  return googleAnalyticConsent && bonnierNewsReynoldsConsent;
}

export function hasPersonalizedAdsConsent() {
  const consentStatus = getConsentStatus();

  if (!consentStatus) return true;

  const personalizedAdsConsent = hasPurposeConsent(consentStatus, undefined, ["create_ads_profile", "select_personalized_ads"]);

  return personalizedAdsConsent;
}